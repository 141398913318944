import moment, {Moment} from "moment";
import React from "react";

export type StartWeekType = "monday" | "saturday" | "sunday"

export enum Views {
  'month'=0,
  'day'=1,
  'year'=2,
}

export interface DateRangePickerProps {
    startDate?: Moment | Date
    endDate?: Moment | Date
    onChange: (startDate: Date, endDate:Date) => void
    startWeek?: StartWeekType
    datePicker: boolean
    locale: string
    minDate?: Moment | Date
    maxDate?: Moment | Date
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>
}

export interface DateRangePickerState {
    datePicker: boolean,
    currentDate: moment.Moment,
    date: Moment,
    activeView: Views,
    startWeek:  StartWeekType,
    year: {
      min: number,
      max: number,
      num: number,
      page: number,
      focused: number
    },
    month: {
      min: number,
      max: number,
      focused: number
    },
    day: {
      start?: Moment,
      end?: Moment,
      min?: Moment,
      max?: Moment,
      hoveredDate?: Moment,
    }
}

export interface Day {
    date: Moment,
    isCurrentDate: Boolean,
    isInRange: Boolean,
    isDisabled: Boolean,
    isInMonth: Boolean,
    isStart: Boolean,
    isEnd: Boolean,
    maybeEnd: Boolean,
    isHovered: Boolean
}

export interface Year {
  year: String
  selected: boolean
  isCurrentYear: boolean
  isYearBlocked: boolean
  isFocused: boolean
}

export interface Month  {
    monthNum: number,
    monthName: string,
    selected: boolean,
    isCurrentMonth: boolean,
    isMonthBlocked: boolean,
    isFocused: boolean
}
